import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../../components/footer"
import Head from "../../components/header"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"

const extlink = (url, text) => (
  <>
    <a href={url} className="text-decoration-none me-1" target="_blank" rel="noopener noreferrer">{text}</a>
  </>
)

const MediaPage = () => (
  <Layout>
    <Head pageTitle="メディア掲載情報 - 株式会社GAZIRU" pageLang="ja" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>メディア掲載情報</h4>
          <h5>2023/01/25</h5>
          <p>日本経済新聞（紙面版）に弊社に関する記事が掲載されました。</p>
          <hr />
          <h5>2022/06/20</h5>
          <p>日本経済新聞（紙面版）に弊社に関する記事が掲載されました。</p>
          <hr />
          <h5>2021/08/03</h5>
          <p>{extlink("https://www.nikko-pb.co.jp/products/detail.php?product_id=5110",
            "日本工業出版 画像ラボ 2021年8月号")}
            に弊社の画像認識技術の解説記事が掲載されました。</p>
          <hr />
          <h5>2021/05/18</h5>
          <p>{extlink("https://japan.zdnet.com/article/35170886/",
            "ZDnet")}
            に弊社に関する記事が掲載されました。</p>
          <hr />
          <h5>2021/04/26</h5>
          <p>{extlink("https://www.nikkei.com/article/DGXZQOUC132VZ0T10C21A4000000/",
            "日経産業新聞(Web版)")}
            に弊社に関する記事が掲載されました。</p>
          <hr />
          <h5>2021/04/20</h5>
          <p>日経産業新聞（紙面版）に弊社に関する記事が掲載されました。</p>
          <hr />
          <h5>2020/10/19</h5>
          <p>{extlink("https://ascii.jp/elem/000/004/029/4029516/",
            "ASCII.jp")}
            に、GAZIRUの個体識別サービスについての記事が掲載されました。</p>
          <hr />
          <h5>2020/09/14</h5>
          <p>{extlink("https://news.mynavi.jp/article/20200908-1289094/",
            "マイナビニュース")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />
          <h5>2020/09/14</h5>
          <p>{extlink("https://techable.jp/archives/137179",
            "TECHABLE")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />
          <h5>2020/09/14</h5>
          <p>{extlink("https://www.excite.co.jp/news/article/Dreaminnovation_vent_news_cTOlDnWvsE/",
            "エキサイトニュース")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />
          <h5>2020/09/08</h5>
          <p>{extlink("https://www.e-logit.com/loginews/2016:102105.php",
            "財経新聞")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />
          <h5>2020/09/08</h5>
          <p>{extlink("https://prtimes.jp/main/html/rd/p/000000001.000062152.html",
            "PR Times")}
            にGAZIRUのプレリリース情報が掲載されました。</p>
          <hr />
          <h5>2020/07/21</h5>
          <p>{extlink("https://www.nikkei.com/article/DGKKZO61751380Q0A720C2TJ1000/",
            "日本経済新聞")}
            に弊社に関する記事が掲載されました。</p>
          <hr />
          <h5>2020/07/20</h5>
          <p>経済産業省 {extlink("https://co-hr-innovation.jp/",
            "出向起業等創出支援事業")}
            の一次公募に選出されました。</p>
          <hr />
          <br />
          <p><Link className="text-decoration-none" to="/">Home</Link></p>

        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default MediaPage
